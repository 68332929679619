import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "clutch/src/Button/Button";

import { readState } from "@/__main__/app-state.mjs";
import { writeSettings } from "@/app/actions.mjs";
import {
  FLASH_PLACEMENT_LEFT,
  flashPlacementOptions,
} from "@/game-lol/constants/constants.mjs";
import { determineFlashPlacement } from "@/game-lol/utils/util.mjs";
import AccountsLol from "@/hub-settings/AccountsLol.jsx";
import {
  PageContainer,
  Panel,
  SettingsContent,
  SettingsDescription,
  SettingsLabel,
  SettingsList,
  SettingsListItem,
} from "@/hub-settings/Common.style.jsx";
import { ToggleSwitch } from "@/hub-settings/ToggleSwitch.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

function LoLSettings() {
  const { t } = useTranslation();
  const {
    settings: {
      lol: {
        displayPopup,
        autoImportItems,
        autoImportRunes,
        autoImportSpells,
        tiltFreeMode,
        defaultFlashPlacement,
        manualFlashPlacement,
        queuePopup,
      },
    },
  } = useSnapshot(readState);

  const flashOptions = useMemo(() => {
    return flashPlacementOptions.map((value) => ({
      name:
        value === FLASH_PLACEMENT_LEFT
          ? t("common:settings.import.preferredFlashPos.left", "[Left]")
          : t("common:settings.import.preferredFlashPos.right", "[Right]"),
      value,
    }));
  }, [t]);

  const flashPlacement = determineFlashPlacement({
    manualFlashPlacement,
    defaultFlashPlacement,
  });

  const gameSettings = [
    {
      name: "displayPopup",
      label: t(
        "common:settings.autoPop",
        "Automatically pop up during champion selection",
      ),
      description: t(
        "common:settings.autoSub",
        "Once you've locked in a Champion, Blitz will automatically pop up to display builds you can import and other helpful info.",
      ),
      input: (
        <ToggleSwitch
          value={displayPopup}
          onChange={({ value }) =>
            writeSettings(["lol", "displayPopup"], value)
          }
        />
      ),
    },
    {
      name: "autoImportItems",
      label: t("common:settings.import.builds", "Auto import builds"),
      description: t(
        "common:settings.import.buildsSubtitle",
        "Import matchup and pro item builds into the in-game item shop.",
      ),
      input: (
        <ToggleSwitch
          value={autoImportItems}
          onChange={({ value }) =>
            writeSettings(["lol", "autoImportItems"], value)
          }
        />
      ),
    },
    {
      name: "autoImportRunes",
      label: t("common:settings.import.runes", "Auto import runes"),
      description: t(
        "common:settings.import.runesSubtitle",
        "Set runes automatically when entering champion select.",
      ),
      input: (
        <ToggleSwitch
          value={autoImportRunes}
          onChange={({ value }) =>
            writeSettings(["lol", "autoImportRunes"], value)
          }
        />
      ),
    },
    {
      name: "autoImportSpells",
      label: t("common:settings.import.spells", "Auto import summoner spells"),
      description: t(
        "common:settings.import.spellsSubtitle",
        "Set summoner spells automatically when entering champion select.",
      ),
      input: (
        <ToggleSwitch
          value={autoImportSpells}
          onChange={({ value }) =>
            writeSettings(["lol", "autoImportSpells"], value)
          }
        />
      ),
    },
    {
      name: "tiltFreeMode",
      label: t("common:settings.tilt", "Tilt-free mode"),
      description: t(
        "common:settings.hide",
        "Hide all player ranks and info in champion select.",
      ),
      input: (
        <ToggleSwitch
          value={tiltFreeMode}
          onChange={({ value }) =>
            writeSettings(["lol", "tiltFreeMode"], value)
          }
        />
      ),
    },
    {
      name: "queuePopup",
      label: t("common:settings.queuePopup", "Enable Match Found Popup"),
      description: t(
        "common:settings.queuePopupSubTitle",
        "A popup that gives you the option to Accept or Decline when a match has been found (LOL & TFT only)",
      ),
      input: (
        <ToggleSwitch
          value={queuePopup}
          onChange={({ value }) => writeSettings(["lol", "queuePopup"], value)}
        />
      ),
    },
    {
      name: "manualFlashPlacement",
      label: t(
        "common:settings.import.preferredFlash.title",
        "Default Flash placement",
      ),
      description: t(
        "common:settings.import.preferredFlashPos.subtitle",
        "Set the preferred placement of Flash when summoner spells are imported.",
      ),
      input: (
        <ButtonGroup>
          {flashOptions.map((option) => {
            return (
              <Button
                key={option.name}
                active={flashPlacement === option.value}
                onClick={() =>
                  writeSettings(["lol", "manualFlashPlacement"], option.value)
                }
              >
                {option.name}
              </Button>
            );
          })}
        </ButtonGroup>
      ),
    },
  ];

  return (
    <PageContainer>
      <AccountsLol />

      <Panel>
        <h5 className="type-article-headline">
          {t("common:settings.gameSettings", "Game Settings")}
        </h5>
        <SettingsList>
          {gameSettings.map(({ name, label, description, input }) => (
            <SettingsListItem key={name}>
              <SettingsContent>
                <SettingsLabel className="type-form--button">
                  {label}
                </SettingsLabel>
                {description && (
                  <SettingsDescription className="type-caption">
                    {description}
                  </SettingsDescription>
                )}
              </SettingsContent>
              {input}
            </SettingsListItem>
          ))}
        </SettingsList>
      </Panel>
    </PageContainer>
  );
}

export default LoLSettings;
